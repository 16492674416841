import { adminAuthGuard } from './core/guards/admin-auth.guard'
import { AuthGuard } from './core/guards/auth.guard'
import { BreadcrumbService } from './core/services/breadcrumb.service'

import { Routes } from '@angular/router'

export const routes: Routes = [
  { path: '', loadComponent: async () => (await import('./features/main')).MainComponent, data: { animation: 'main' } },
  { path: 'ui-kit', loadComponent: async () => (await import('./ui-kit')).UiKitComponent, data: { animation: 'ui-kit' } },
  {
    path: 'profile',
    loadComponent: async () => (await import('./features/user/components/user-profile')).UserProfileComponent,
    data: { animation: 'user-profile' },
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/profile',
    loadComponent: async () => (await import('./features/admin/components/admin-profile')).AdminProfileComponent,
    data: { animation: 'admin-profile' },
    canActivate: [adminAuthGuard],
  },
  {
    path: 'user',
    loadComponent: async () => (await import('./features/user')).UserComponent,
    data: { animation: 'Main' },
    loadChildren: () => import('./features/user/user.routes').then((r) => r.USER_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadComponent: async () => (await import('./features/admin')).AdminComponent,
    data: { animation: 'Admin' },
    loadChildren: () => import('./features/admin/admin.routes').then((r) => r.ADMIN_ROUTES),
    canActivate: [adminAuthGuard],
  },

  { path: 'devices/:id', loadComponent: async () => (await import('./features/devices')).DevicesComponent, data: { animation: 'devices' } },
  {
    path: 'news',
    loadComponent: async () => (await import('./features/news')).NewsComponent,
    data: { animation: 'news' },
    loadChildren: async () => (await import('./features/news/news.routes')).NEWS_ROUTES,
  },
  { path: 'partners', loadComponent: async () => (await import('./features/partners')).PartnersComponent, data: { animation: 'partners' } },
  {
    path: 'contacts',
    loadComponent: async () => (await import('./features/contacts')).ContactsComponent,
    data: { animation: 'contacts' },
  },
  {
    path: 'order',
    loadComponent: async () => (await import('./features/order')).OrderComponent,
    data: { animation: 'order' },
  },
  { path: 'login', loadComponent: async () => (await import('./features/login')).LoginComponent, data: { animation: 'login' } },
  { path: 'about', loadComponent: async () => (await import('./features/about-sign')).AboutSignComponent, data: { animation: 'about' } },
  {
    path: 'faq',
    loadComponent: async () => (await import('./features/faq')).FaqComponent,
    data: { animation: 'faq' },
    loadChildren: () => import('./features/faq/faq.routes').then((r) => r.FAQ_ROUTES),
    providers: [BreadcrumbService],
  },
  // { path: 'contract', loadComponent: async () => (await import('./features/contract')).ContractComponent, data: { animation: 'contract' } },
  { path: 'software', loadComponent: async () => (await import('./features/software')).SoftwareComponent, data: { animation: 'software' } },
  {
    path: 'contracts',
    loadComponent: async () => (await import('./features/contracts')).ContractsComponent,
    data: { animation: 'contracts' },
  },
  {
    path: 'reglament',
    loadComponent: async () => (await import('./features/reglament')).ReglamentComponent,
    data: { animation: 'reglament' },
  },
  {
    path: 'keys',
    loadComponent: async () => (await import('./features/keys')).KeysComponent,
    data: { animation: 'keys' },
  },
  {
    path: 'digital-signature',
    loadComponent: async () => (await import('./features/digital-signature')).DigitalSignatureComponent,
    data: { animation: 'digital-signature' },
  },
  {
    path: 'search-certificates',
    loadComponent: async () => (await import('./features/search-certificates')).SearchCertificatesComponent,
    loadChildren: async () => (await import('./features/search-certificates/search-certificates.routes')).SEARCH_CERTIFICATES_ROUTES,
    data: { animation: 'search-certificates' },
  },
  {
    path: 'change-status',
    loadComponent: async () => (await import('./features/change-status')).ChangeStatusComponent,
    data: { animation: 'change-status' },
  },
  {
    path: 'partnerships',
    loadComponent: async () => (await import('./features/partnerships')).PartnershipsComponent,
    data: { animation: 'partnerships' },
  },
  {
    path: 'update',
    loadComponent: async () => (await import('./features/update-certificate')).UpdateCertificateComponent,
    data: { animation: 'update-certificate' },
  },
  {
    path: 'sign',
    loadComponent: async () => (await import('./features/sign-document')).SignDocumentComponent,
    data: { animation: 'sign-document' },
  },
  {
    path: 'check-sign',
    loadComponent: async () => (await import('./features/check-sign')).CheckSignComponent,
    data: { animation: 'check-sign' },
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
]
