import { Observable, filter } from 'rxjs'

import { FooterComponent } from './common/footer/footer.component'
import { LayoutService } from './core/services/layout.service'
import { SsrService } from './core/services/ssr.service'
import { UiKitComponent } from './ui-kit/ui-kit.component'

import { AsyncPipe, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, UiKitComponent, AsyncPipe, FooterComponent, NgIf, AsyncPipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'knedp-public-portal'

  isFooter$: Observable<boolean> = this.layoutService.isFooter$

  isShadow$: Observable<boolean> = this.layoutService.isShadow$

  constructor(
    private router: Router,
    private destroy: DestroyRef,
    private ssrService: SsrService,
    private layoutService: LayoutService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd && this.ssrService.isBrowser),
        takeUntilDestroyed(this.destroy),
      )
      .subscribe(() => {
        window.scrollTo(0, 0)
      })
  }
}
